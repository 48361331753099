export default {
  path: '/sso/organization',
  title: '组织架构',
  children: [{
    path: '/sso/organization/framework',
    title: '组织架构'
  }, {
    path: '/sso/organization/staff-manage',
    title: '员工管理'
  }]
};