import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import _defineProperty from "/app/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { on, off } from 'view-design/src/utils/dom';
import { setMatchMedia } from 'view-design/src/utils/assist';
import { mapMutations } from 'vuex';
setMatchMedia();
export default {
  name: 'app',
  methods: _objectSpread(_objectSpread({}, mapMutations('admin/layout', ['setDevice'])), {}, {
    handleWindowResize: function handleWindowResize() {
      this.handleMatchMedia();
    },
    handleMatchMedia: function handleMatchMedia() {
      var matchMedia = window.matchMedia;

      if (matchMedia('(max-width: 600px)').matches) {
        this.setDevice('Mobile');
      } else if (matchMedia('(max-width: 992px)').matches) {
        this.setDevice('Tablet');
      } else {
        this.setDevice('Desktop');
      }
    }
  }),
  mounted: function mounted() {
    on(window, 'resize', this.handleWindowResize);
    this.handleMatchMedia();
  },
  beforeDestroy: function beforeDestroy() {
    off(window, 'resize', this.handleWindowResize);
  }
};