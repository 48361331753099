import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import _defineProperty from "/app/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import iMenuHeadTitle from "./title";
import { mapState, mapGetters } from 'vuex';
import { getStyle } from 'view-design/src/utils/assist';
import { on, off } from 'view-design/src/utils/dom';
import { throttle } from 'lodash';
export default {
  name: 'iMenuHead',
  components: {
    iMenuHeadTitle: iMenuHeadTitle
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState('admin/layout', ['isMobile'])), mapState('admin/menu', ['headerName'])), mapGetters('admin/menu', ['filterHeader'])),
  data: function data() {
    return {
      handleResize: function handleResize() {},
      isMenuLimit: false,
      menuMaxWidth: 0 // 达到这个值后，menu 就显示不下了

    };
  },
  methods: {
    handleGetMenuHeight: function handleGetMenuHeight() {
      var menuWidth = parseInt(getStyle(this.$el, 'width'));
      var $menu = this.$refs.menu;

      if ($menu) {
        var menuHeight = parseInt(getStyle(this.$refs.menu.$el, 'height'));

        if (menuHeight > 64) {
          if (!this.isMenuLimit) {
            this.menuMaxWidth = menuWidth;
          }

          this.isMenuLimit = true;
        }
      } else if (menuWidth >= this.menuMaxWidth) {
        this.isMenuLimit = false;
      }
    }
  },
  watch: {
    filterHeader: function filterHeader() {
      this.handleGetMenuHeight();
    },
    isMobile: function isMobile() {
      this.handleGetMenuHeight();
    }
  },
  mounted: function mounted() {
    this.handleResize = throttle(this.handleGetMenuHeight, 100, {
      leading: false
    });
    on(window, 'resize', this.handleResize);
    this.handleGetMenuHeight();
  },
  beforeDestroy: function beforeDestroy() {
    off(window, 'resize', this.handleResize);
  }
};