export default {
  path: '/sso/permission-assignment/data-permission',
  name: 'dataPermission',
  meta: {
    cache: true,
    auth: true,
    title: '数据权限管理'
  },
  component: function component() {
    return import('@/pages/sso/permission-assignment/data-permission');
  }
};