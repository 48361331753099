var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("Modal", {
    attrs: {
      value: _vm.value,
      title: "规则说明",
      width: 522,
      "footer-hide": true
    },
    on: {
      "on-visible-change": _vm.visibleChange
    }
  }, [_c("div", {
    staticClass: "instructions-content"
  }, [_vm._t("default")], 2), _c("div", {
    staticClass: "footer"
  }, [_c("Button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.ok
    }
  }, [_vm._v("知道了")])], 1)]);
};

var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };