export default {
  path: '/sso/system/setting',
  name: 'systemSetting',
  meta: {
    cache: true,
    auth: true,
    title: '系统设置'
  },
  component: function component() {
    return import('@/pages/sso/system/setting');
  }
};