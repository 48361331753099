export default {
  path: '/sso/permission-assignment/role-management',
  name: 'roleManagement',
  meta: {
    cache: true,
    auth: true,
    title: '角色管理'
  },
  component: function component() {
    return import(
    /*webpackChunkName:'sso-account-manage'*/
    '@/pages/sso/permission-assignment/role-management');
  }
};