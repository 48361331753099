import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import _defineProperty from "/app/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { mapState, mapActions } from 'vuex';
import iPasswordReset from "./passwd-reset";
export default {
  name: 'iHeaderUser',
  components: {
    iPasswordReset: iPasswordReset
  },
  data: function data() {
    return {
      isShowPasswdModal: false
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState('admin/user', ['info'])), mapState('admin/layout', ['isMobile', 'logoutConfirm'])),
  methods: _objectSpread(_objectSpread({}, mapActions('admin/account', ['logout'])), {}, {
    handleClick: function handleClick(name) {
      switch (name) {
        case 'logout':
          this.logout({
            confirm: this.logoutConfirm
          });
          break;

        case "password-reset":
          this.isShowPasswdModal = !this.isShowPasswdModal;
      }
    }
  })
};