export default {
  path: '/sso/system',
  title: '系统设置',
  children: [{
    path: '/sso/system/system-announcement',
    title: '系统公告',
    children: [{
      path: '/sso/system/system-announcement/detail',
      title: '公告内容',
      hide: true
    }]
  }, {
    path: '/sso/system/setting',
    title: '系统设置'
  }]
};