var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("Modal", {
    attrs: {
      value: _vm.value,
      title: "修改密码",
      width: "450",
      "mask-closable": false,
      closable: false,
      "footer-hide": ""
    },
    on: {
      "on-ok": _vm.ok,
      "on-cancel": _vm.cancel
    }
  }, [_c("div", {
    staticStyle: {
      "font-size": "12px",
      "padding-left": "10px",
      "margin-bottom": "20px"
    }
  }, [_c("p", [_vm._v("新密码由6-20个字符组成")]), _c("p", [_c("Icon", {
    attrs: {
      type: "ios-information-circle",
      size: "14",
      color: "red"
    }
  }), _vm._v("\n\t\t\t\t注意：修改密码后需要重新登录\n\t\t\t")], 1)]), _c("Form", {
    ref: "passwordForm",
    attrs: {
      "label-width": 100,
      model: _vm.info,
      rules: _vm.rules
    }
  }, [_c("FormItem", {
    attrs: {
      label: "输入原密码",
      prop: "password"
    }
  }, [_c("fk-password", {
    attrs: {
      placeholder: "至少6位密码，区分大小写"
    },
    model: {
      value: _vm.info.password,
      callback: function callback($$v) {
        _vm.$set(_vm.info, "password", $$v);
      },
      expression: "info.password"
    }
  })], 1), _c("FormItem", {
    attrs: {
      label: "输入新密码",
      prop: "newPassword"
    }
  }, [_c("fk-password", {
    attrs: {
      placeholder: "至少6位密码，区分大小写"
    },
    model: {
      value: _vm.info.newPassword,
      callback: function callback($$v) {
        _vm.$set(_vm.info, "newPassword", $$v);
      },
      expression: "info.newPassword"
    }
  })], 1), _c("FormItem", {
    attrs: {
      label: "确认新密码",
      prop: "confirmPassword"
    }
  }, [_c("fk-password", {
    attrs: {
      placeholder: "至少6位密码，区分大小写"
    },
    model: {
      value: _vm.info.confirmPassword,
      callback: function callback($$v) {
        _vm.$set(_vm.info, "confirmPassword", $$v);
      },
      expression: "info.confirmPassword"
    }
  })], 1), _c("div", {
    staticStyle: {
      padding: "0 10px"
    }
  }, [_c("Button", {
    staticStyle: {
      "margin-right": "10px"
    },
    attrs: {
      disabled: _vm.isSubmiting
    },
    on: {
      click: _vm.cancel
    }
  }, [_vm._v("取消")]), _c("Button", {
    attrs: {
      type: "primary",
      loading: _vm.isSubmiting
    },
    on: {
      click: _vm.ok
    }
  }, [_vm._v("修改")])], 1)], 1)], 1);
};

var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };