var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("GlobalFooter", {
    staticClass: "i-copyright",
    staticStyle: {
      margin: "0px"
    },
    attrs: {
      links: _vm.links
    }
  }, [_c("div", {
    attrs: {
      slot: "copyright"
    },
    slot: "copyright"
  }, [_vm._v("\n\t\t\t" + _vm._s(_vm.copyright) + " "), _c("a", {
    attrs: {
      href: "https://beian.miit.gov.cn/",
      target: "_blank"
    }
  }, [_vm._v("粤ICP备18104455号-2")])])]);
};

var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };