export default {
  path: '/sso/permission-assignment/permission-management',
  name: 'permissionManagement',
  meta: {
    cache: true,
    auth: true,
    title: '功能权限管理'
  },
  component: function component() {
    return import('@/pages/sso/permission-assignment/permission-management');
  }
};