var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("span", [_vm.menu.children === undefined || !_vm.menu.children.length || _vm.menu.children.every(function (i) {
    return i.hide;
  }) ? _c("i-menu-side-item", {
    attrs: {
      menu: _vm.menu
    }
  }) : _c("Submenu", {
    attrs: {
      name: _vm.menu.path
    }
  }, [_c("template", {
    slot: "title"
  }, [_c("i-menu-side-title", {
    attrs: {
      menu: _vm.menu
    }
  })], 1), _vm._l(_vm.menu.children.filter(function (i) {
    return !i.hide;
  }), function (item, index) {
    return [item.children === undefined || !item.children.length ? _c("i-menu-side-item", {
      key: index,
      attrs: {
        menu: item
      }
    }) : _c("i-menu-side-submenu", {
      key: index,
      attrs: {
        menu: item
      }
    })];
  })], 2)], 1);
};

var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };