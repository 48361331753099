import "core-js/modules/es6.function.name";

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("ul", {
    staticClass: "dsp-legend-list",
    style: "text-align: ".concat(_vm.align)
  }, _vm._l(_vm.data, function (item, index) {
    return _c("li", {
      key: index + item.name + item.value
    }, [_c("i", {
      staticClass: "mark",
      style: "background-color:".concat(item.color ? item.color : _vm.colors[index], ";")
    }), _c("span", [_vm._v("\n            " + _vm._s(item.name) + " "), item.tip ? _c("dsp-tip", {
      attrs: {
        content: item.tip,
        size: "16",
        color: item.color ? item.color : _vm.colors[index],
        theme: "light"
      }
    }) : _vm._e()], 1), item.valueType == "INT" || !item.valueType ? _c("span", [_vm._v(" " + _vm._s(_vm._f("formatNumber")(item.value)) + " ")]) : item.valueType == "DOUBLE" ? _c("span", [_vm._v(" " + _vm._s(_vm._f("formatFloatNumber")(item.value)) + " ")]) : item.valueType == "PERCENT" ? _c("span", [_vm._v(" " + _vm._s(_vm._f("formatPercent")(item.value)) + " ")]) : item.valueType == "MONEY" ? _c("span", [_vm._v(" " + _vm._s(_vm._f("formatMoney")(item.value, item.unit)) + " ")]) : _vm._e()]);
  }), 0);
};

var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };