var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "echart-box"
  }, [_c("dsp-legend-list", {
    staticStyle: {
      "margin-left": "50px"
    },
    attrs: {
      data: _vm.legendList,
      align: "left"
    }
  }), _c("dsp-echart", {
    attrs: {
      tooltipFormatter: _vm.tooltipFormatter,
      xAxisData: _vm.xAxisData,
      series: _vm.series,
      yAxis: _vm.yAxis,
      height: "175",
      legendLeft: "center",
      group: _vm.group,
      xAxisBoundaryGap: _vm.xAxisBoundaryGap
    }
  })], 1);
};

var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };