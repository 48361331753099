var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "text-bar"
  }, [_vm._t("label", function () {
    return [_vm.label ? _c("div", {
      staticClass: "label"
    }, [_vm._v(_vm._s(_vm.label))]) : _vm._e()];
  }), _c("div", {
    key: _vm.contentText,
    staticClass: "content",
    attrs: {
      title: _vm.contentText
    }
  }, [_vm._t("default")], 2), _vm.$slots.suffix ? _c("div", {
    staticClass: "suffix"
  }, [_vm._t("suffix")], 2) : _vm._e()], 2);
};

var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };