var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("Tooltip", {
    attrs: {
      content: _vm.site ? _vm.site.toUpperCase() : null,
      placement: _vm.placement,
      theme: _vm.theme,
      transfer: ""
    }
  }, [_c("span", {
    staticClass: "site-icon",
    style: _vm.style
  })]);
};

var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };