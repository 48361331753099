import "core-js/modules/es6.regexp.replace";

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", [_c("MenuItem", {
    attrs: {
      to: _vm.menu.path,
      replace: _vm.menu.replace,
      target: _vm.menu.target,
      name: _vm.menu.path
    }
  }, [_c("i-menu-side-title", {
    attrs: {
      menu: _vm.menu,
      "hide-title": _vm.hideTitle
    }
  })], 1)], 1);
};

var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };