import _Option2 from "element-ui/lib/theme-chalk/option.css";
import "element-ui/lib/theme-chalk/base.css";
import _Option from "element-ui/lib/option";
import _Select2 from "element-ui/lib/theme-chalk/select.css";
import "element-ui/lib/theme-chalk/base.css";
import _Select from "element-ui/lib/select";
import "/app/node_modules/core-js/modules/es6.array.iterator.js";
import "/app/node_modules/core-js/modules/es6.promise.js";
import "/app/node_modules/core-js/modules/es6.object.assign.js";
import "/app/node_modules/core-js/modules/es7.promise.finally.js";
// Vue
import Vue from 'vue';
import App from "./App"; // 插件

import plugins from '@/plugins'; // store

import store from '@/store/index'; // iView 和 iView Pro

import iView from 'view-design';
import iViewPro from '@/libs/iview-pro/iview-pro.min.js'; // 菜单和路由

import router from "./router"; // import menuHeader from '@/menu/header';

import menuSider from '@/menu/sider';
import { frameInRoutes } from '@/router/routes'; // 方法

import { getHeaderName, getMenuSider, getSiderSubmenu } from '@/libs/system'; // 内置组件

import iLink from '@/components/link'; // 使用样式，修改主题可以在 styles 目录下创建新的主题包并修改 iView 默认的 less 变量
// 参考 https://www.iviewui.com/docs/guide/theme

import "./styles/index.less";
import "./libs/iview-pro/iview-pro.css"; // 引入vxe-table

import 'xe-utils';
import VXETable from 'vxe-table';
import 'vxe-table/lib/style.css';
Vue.use(plugins);
Vue.use(iView);
Vue.use(iViewPro);
Vue.component('i-link', iLink);
Vue.use(VXETable);
import Vue2OrgTree from 'vue-tree-color';
Vue.use(Vue2OrgTree); //***** 数据处理方法 *****

import { nan2zero, fixed2, formatNumber, formatFloatNumber, formatMoney, formatPercent, setTitle, exportAsExcel, formatMillion } from "@/libs/data-handler";
/***** @六月 全局过滤器 *****/
// 全局注册filter

Vue.filter("nan2zero", nan2zero);
Vue.filter("fixed2", fixed2);
Vue.filter("formatNumber", formatNumber);
Vue.filter("formatFloatNumber", formatFloatNumber);
Vue.filter("formatMoney", formatMoney);
Vue.filter("formatPercent", formatPercent);
Vue.filter("formatMillion", formatMillion); // 全局注册filter

/***** @六月 全局方法 *****/

Vue.prototype.$nan2zero = nan2zero;
Vue.prototype.$fixed2 = fixed2;
Vue.prototype.$formatNumber = formatNumber;
Vue.prototype.$formatFloatNumber = formatFloatNumber;
Vue.prototype.$formatMoney = formatMoney;
Vue.prototype.$formatPercent = formatPercent;
Vue.prototype.$setTitle = setTitle;
Vue.prototype.$exportAsExcel = exportAsExcel;
/***** @六月 全局方法 *****/

import { getWeekStartDate, getWeekEndDate } from "@/libs/date/week";
Vue.prototype.$getWeekStartDate = getWeekStartDate;
Vue.prototype.$getWeekEndDate = getWeekEndDate; //*****@六月 全局组件******/

import fkComponents from "@/components/fk";
import dspComponents from "@/components/dsp";
Vue.use(fkComponents);
Vue.use(dspComponents); //*****end 全局组件******/
//***** 混用element ******/

import 'element-ui/lib/theme-chalk/index.css';
Vue.use(_Select, {
  size: "mini"
});
Vue.use(_Option, {
  size: "mini"
}); //*****end 混用element ******/

new Vue({
  store: store,
  router: router,
  render: function render(h) {
    return h(App);
  },
  created: function created() {
    // 加载用户登录的数据
    this.$store.dispatch('admin/account/load'); // 处理路由 得到每一级的路由设置

    this.$store.commit('admin/page/init', frameInRoutes); // 设置顶栏菜单
    // this.$store.commit('admin/menu/setHeader', menuHeader);
    // 初始化全屏监听

    this.$store.dispatch('admin/layout/listenFullscreen');
  },
  watch: {
    // 监听路由 控制侧边栏显示 标记当前顶栏菜单（如需要）
    '$route': function $route(_ref, old) {
      var path = _ref.path;
      // console.log(old.path)
      //   if (old.path === '/' || old.path === '/login'){
      //     store.state.admin.account.isAddOffsetTop = false
      //   }else{
      //     store.state.admin.account.isAddOffsetTop = true;
      //   }
      var headerName = getHeaderName(path, menuSider); // 在 404 时，是没有 headerName 的

      if (headerName !== null) {
        this.$store.commit('admin/menu/setHeaderName', headerName); // const filterMenuSider = getMenuSider(menuSider, headerName);

        this.$store.commit('admin/menu/setSider', menuSider);
        this.$store.commit('admin/menu/setActivePath', path);
        var openNames = getSiderSubmenu(path, menuSider);
        this.$store.commit('admin/menu/setOpenNames', openNames);
      }
    }
  }
}).$mount('#app');