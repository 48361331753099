import Vue from 'vue';
import Vuex from 'vuex';
import admin from "./modules/admin";
import filter from "./modules/filter";
Vue.use(Vuex);
export default new Vuex.Store({
  modules: {
    admin: admin,
    filter: filter
  }
});