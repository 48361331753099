var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "fk-page"
  }, [_vm._t("extra"), _c("Page", {
    staticStyle: {
      float: "right"
    },
    attrs: {
      size: _vm.size,
      total: _vm.total,
      "page-size-opts": _vm.pageSizeOpts,
      current: _vm.current,
      "page-size": _vm.pageSize,
      simple: _vm.simple,
      className: _vm.className,
      transfer: _vm.transfer,
      "show-total": _vm.showTotal,
      "show-elevator": _vm.showElevator,
      "show-sizer": _vm.showSizer
    },
    on: {
      "on-change": _vm.pageChange,
      "on-page-size-change": _vm.pageSizeChange
    }
  })], 2);
};

var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };