export default {
  name: 'text-bar',
  props: {
    label: {
      type: String
    },
    contentText: {
      type: String
    }
  },
  computed: {
    width: function width() {
      var labelWidth = "".concat(this.labelWidth).trim();
      var width = null;

      if (this.labelWidth != null && labelWidth) {
        var isPercent = labelWidth.indexOf('%') !== -1;
        width = isPercent ? labelWidth : "".concat(labelWidth, "px");
      }

      return width;
    }
  }
};