export default {
  path: '/sso/permission-assignment/data-sharing',
  name: 'dataSharing',
  meta: {
    cache: true,
    auth: true,
    title: '数据开放权限'
  },
  component: function component() {
    return import(
    /*webpackChunkName:'sso-account-manage'*/
    '@/pages/sso/permission-assignment/data-sharing');
  }
};