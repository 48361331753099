import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import _defineProperty from "/app/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { mapActions } from "vuex";
/********************************************* 密码校验 **************************************************/
// 密码对象，写在外面是为了方便写二次校验

var info = {
  password: null,
  newPassword: null,
  confirmPassword: null
};
/************** 校验长度 ****************/

var lengthValidate = function lengthValidate(rule, value, cb) {
  var length = value.trim().length;

  if (length < 6) {
    cb(new Error('密码不能少于6位'));
  } else if (length > 20) {
    cb(new Error('密码不能多于20位'));
  } else {
    cb();
  }
};
/************** 校验字符 ****************/


var spaceRegexp = /\s/g;
var wordRegexp = /^(?![0-9]+$)(?![a-z]+$)(?![A-Z]+$)(?!([^(0-9a-zA-Z)])+$)[0-9a-zA-Z]{6,20}$/;

var ruleValidate = function ruleValidate(rule, value, cb) {
  if (spaceRegexp.test(value)) {
    cb(new Error('密码不能包含空格'));
  } else if (!wordRegexp.test(value)) {
    cb(new Error("密码只能由大小写字母及数字中至少两种组成"));
  } else {
    cb();
  }
};
/************** 校验新密码与确认密码是否一致 ****************/


var isSameAsNewPassword = function isSameAsNewPassword(rule, value, cb) {
  if (value === info.newPassword) {
    cb();
  } else {
    cb(new Error('与新密码不一致'));
  }
};
/******************************************end 密码校验 **************************************************/


export default {
  name: 'i-password-reset',
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      //修改密码的信息
      info: info,
      isSubmiting: false,
      //校验规则
      rules: {
        password: [{
          required: true,
          type: 'string',
          message: '请输入原密码',
          trigger: 'blur'
        }],
        newPassword: [{
          required: true,
          type: 'string',
          message: '请输入新密码',
          trigger: 'blur'
        }, {
          validator: lengthValidate,
          trigger: 'blur'
        } // { validator:ruleValidate ,trigger:'blur' },
        ],
        confirmPassword: [{
          required: true,
          type: 'string',
          message: '请输入再次输入新密码',
          trigger: 'blur'
        }, {
          validator: isSameAsNewPassword,
          trigger: 'blur'
        }, {
          validator: lengthValidate,
          trigger: 'blur'
        } // { validator:ruleValidate ,trigger:'blur' },
        ]
      }
    };
  },
  methods: _objectSpread(_objectSpread({}, mapActions('admin/account', ['PasswordReset'])), {}, {
    ok: function ok() {
      var _this = this;

      this.$refs['passwordForm'].validate(function (result) {
        return result && _this.passwordReset();
      });
    },
    cancel: function cancel() {
      this.$refs['passwordForm'].resetFields();
      this.$emit('input', false);
    },
    passwordReset: function passwordReset() {
      var _this2 = this;

      this.isSubmiting = true;
      var _this$info = this.info,
          oldPassword = _this$info.password,
          newPassword = _this$info.newPassword;
      this.PasswordReset({
        oldPassword: oldPassword,
        newPassword: newPassword
      }).then(function (res) {
        if (res.flag === 0) {
          _this2.$emit('input', false);
        }

        _this2.isSubmiting = false;
      }).catch(function (e) {
        return _this2.isSubmiting = false;
      });
    }
  })
};