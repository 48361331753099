import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import _defineProperty from "/app/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import iMenuSideItem from "./menu-item";
import iMenuSideSubmenu from "./submenu";
import iMenuSideCollapse from "./menu-collapse";
import { mapState, mapGetters } from 'vuex';
export default {
  name: 'iMenuSide',
  components: {
    iMenuSideItem: iMenuSideItem,
    iMenuSideSubmenu: iMenuSideSubmenu,
    iMenuSideCollapse: iMenuSideCollapse
  },
  props: {
    hideLogo: {
      type: Boolean,
      default: false
    }
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState('admin/layout', ['siderTheme', 'menuAccordion', 'menuCollapse'])), mapState('admin/menu', ['activePath', 'openNames'])), mapGetters('admin/menu', ['filterSider'])),
  watch: {
    '$route': {
      handler: function handler() {
        this.handleUpdateMenuState();
      },
      immediate: true
    },
    // 在展开/收起侧边菜单栏时，更新一次 menu 的状态
    menuCollapse: function menuCollapse() {
      this.handleUpdateMenuState();
    }
  },
  methods: {
    handleUpdateMenuState: function handleUpdateMenuState() {
      var _this = this;

      this.$nextTick(function () {
        if (_this.$refs.menu) {
          _this.$refs.menu.updateActiveName();

          if (_this.menuAccordion) _this.$refs.menu.updateOpened();
        }
      });
    }
  }
};