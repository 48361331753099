import "core-js/modules/es6.string.link";
import "core-js/modules/es6.number.parse-int";
import "core-js/modules/es6.number.constructor";
import { compute } from "./util";
export default {
  name: "dsp-ellipsis",
  props: {
    content: {
      // 文本内容
      type: String,
      required: true
    },
    line: {
      // 需要省略的行数
      type: Number,
      default: 1
    },
    width: {
      // 文本内容宽度
      type: Number,
      default: 100
    },
    lineHeight: {
      // 行高，多行省略时必填
      type: Number,
      default: 20
    },
    placement: {
      // 同tooltip属性placement
      type: String,
      default: "top"
    },
    delay: {
      // 同tooltip属性openDelay
      type: [String, Number],
      default: "500"
    },
    link: {
      type: Boolean,
      default: false
    },
    row: {
      type: Object,
      default: function _default() {}
    }
  },
  render: function render(h) {
    var line = this.line;
    var content = this.content;
    var style = {
      width: "".concat(this.width, "px")
    };

    if (line === 1) {
      style.whiteSpace = "nowrap";
    } else {
      style.height = "".concat(line * this.lineHeight, "px");
      style.lineHeight = "".concat(this.lineHeight, "px");
    }

    if (compute(content, style)) {
      return h("el-tooltip", {
        props: {
          placement: this.placement,
          openDelay: Number.parseInt(this.delay),
          content: content
        }
      }, [this.getText(h, content, true)]);
    }

    return this.getText(h, content);
  },
  methods: {
    getText: function getText(h, content, overflow) {
      if (this.link) {
        return h("router-link", {
          props: {
            to: {
              name: "dsp-media-plan-detail-orders",
              params: {
                id: this.row.planId,
                ordersId: this.row.ordersDbId
              }
            }
          },
          class: this.getClass(overflow),
          style: this.getStyle(overflow)
        }, content);
      }

      return h("div", {
        class: this.getClass(overflow),
        style: this.getStyle(overflow)
      }, content);
    },
    getClass: function getClass(overflow) {
      var list = ["dsp-ellipsis", "dsp-ellipsis-component"];
      if (!overflow) return list;
      list.push(this.line === 1 ? "is-overflow-single" : "is-overflow");
      return list;
    },
    getStyle: function getStyle(overflow) {
      if (!overflow) return {};
      var line = this.line;
      if (line === 1) return {};
      return {
        "-webkit-line-clamp": line,
        height: "".concat(line * this.lineHeight, "px")
      };
    }
  }
};