import "core-js/modules/web.dom.iterable";

function importAll(r) {
  return r.keys().reduce(function (total, item) {
    item !== './index.js' && total.push(r(item).default);
    return total;
  }, []);
}

var routes = importAll(require.context('./', true, /\.js$/));
export default routes;