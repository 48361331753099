var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("i-link", {
    staticClass: "i-layout-header-logo",
    class: {
      "i-layout-header-logo-stick": !_vm.isMobile
    },
    attrs: {
      to: "{name:'home'}"
    }
  }, [_vm.isMobile ? _c("img", {
    attrs: {
      src: require("@/assets/svg/logo.svg")
    }
  }) : _vm.headerTheme === "light" ? _c("img", {
    attrs: {
      src: "https://static2.4kmiles.com/school/static/v3027/pc/img/header/logo.svg"
    }
  }) : _c("img", {
    attrs: {
      src: "https://static2.4kmiles.com/school/static/v3027/pc/img/header/logo.svg"
    }
  })]);
};

var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };