import "core-js/modules/es6.regexp.replace";
import "core-js/modules/es6.number.constructor";
import util from '@/libs/util'; //********* 包括 浮点数保留两位小数，英式数值显示，非0转为0，百分比等 **********//
//非数字转换为0

export var nan2zero = function nan2zero(v) {
  return isNaN(v) || v == null ? 0 : Number(v);
}; //数字展示转换为2位小数

export var fixed2 = function fixed2(v) {
  return nan2zero(v).toFixed(2);
}; //格式化数值 为 英式 格式

export var formatNumber = function formatNumber(v) {
  return "".concat(nan2zero(v)).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}; //格式化数值为英式数值格式，并保持2位小数

export var formatFloatNumber = function formatFloatNumber(v) {
  return "".concat(fixed2(v)).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}; //格式化数值 为 英式 货币 显示

export var formatMoney = function formatMoney(v) {
  var unit = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '$';
  return "".concat(unit ? unit : '', " ").concat(fixed2(v)).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}; //格式化数值为百分比

export var formatPercent = function formatPercent(v) {
  return "".concat(fixed2(v * 100), "%");
}; //**************** 设置页面标题 **************//

export var setTitle = function setTitle(title) {
  util.title({
    title: title
  });
}; //**************** 导出为excel **************//

export var exportAsExcel = function exportAsExcel(data, fileName, cb) {
  /*保存文件流*/
  var blob = new Blob([data], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'
  });
  var url = window.URL.createObjectURL(blob);
  var link = document.createElement('a');
  link.href = url;
  link.target = "_blank";
  if (fileName) link.download = fileName;
  link.click();
  window.URL.revokeObjectURL(url);
  link = null;
  cb && cb instanceof Function && cb();
};
export var formatMillion = function formatMillion(value) {
  var num;

  if (value >= 1000000) {
    num = formatNumber(Math.floor(value / 100) / 10000) + ' M';
  } else if (10000 <= value && value < 1000000) {
    num = formatNumber(Math.floor(value / 100) / 100) + ' W';
  } else if (1000 <= value && value < 10000) {
    num = formatFloatNumber(value / 1000) + ' K';
  } else {
    num = formatFloatNumber(value);
  }

  return num;
};