import dayjs from 'dayjs';
export default {
  name: 'i-copyright',
  data: function data() {
    return {
      links: [],
      copyright: ''
    };
  },
  created: function created() {
    var year = dayjs().format('YYYY');
    this.copyright = "Copyright \xA9 2019-".concat(year, " 4KMILES.COM");
  }
};