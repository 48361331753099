import "core-js/modules/es6.number.constructor";
export default {
  name: 'dsp-input-number',
  props: {
    value: {
      type: Number
    },
    toFixed2: {
      type: Boolean,
      default: true
    }
  },
  data: function data() {
    return {
      inputValue: this.value
    };
  },
  watch: {},
  methods: {
    numBlur: function numBlur() {
      var value = Number(this.inputValue);

      if (this.toFixed2) {
        this.inputValue = Number(value.toFixed(2));
      } else {
        this.inputValue = Number(value.toFixed());
      }

      this.$emit('input', this.inputValue);
    }
  }
};