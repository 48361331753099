export default {
  path: '/sso/system/system-announcement/detail',
  name: "announcementDatail",
  meta: {
    cache: true,
    auth: true,
    title: '公告内容'
  },
  component: function component() {
    return import(
    /*webpackChunkName:'sso-account-manage'*/
    '@/pages/sso/system/system-announcement/detail');
  }
};