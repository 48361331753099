export default {
  us: require('@/assets/images/sites-icon/美国@1x.png'),
  ca: require('@/assets/images/sites-icon/加拿大@1x.png'),
  mx: require('@/assets/images/sites-icon/墨西哥 @1x.png'),
  uk: require('@/assets/images/sites-icon/英国@1x.png'),
  de: require('@/assets/images/sites-icon/德国@1x.png'),
  fr: require('@/assets/images/sites-icon/法国@1x.png'),
  it: require('@/assets/images/sites-icon/意大利@1x.png'),
  es: require('@/assets/images/sites-icon/西班牙@1x.png'),
  in: require('@/assets/images/sites-icon/印度@1x.jpg'),
  ae: require('@/assets/images/sites-icon/阿拉伯联合酋长国@1x.jpg'),
  sa: require('@/assets/images/sites-icon/沙特阿拉伯国旗@1x.png'),
  jp: require('@/assets/images/sites-icon/日本@1x.png'),
  au: require('@/assets/images/sites-icon/澳大利亚@1x.jpg'),
  br: require('@/assets/images/sites-icon/巴西@1x.png'),
  nl: require('@/assets/images/sites-icon/荷兰@1x.png')
};