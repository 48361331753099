export default {
  path: '/sso/permission-assignment/data-sharing/details',
  name: 'data-sharing-details',
  meta: {
    cache: true,
    auth: true,
    title: '数据开放权限详情'
  },
  component: function component() {
    return import(
    /*webpackChunkName:'sso-account-manage'*/
    '@/pages/sso/permission-assignment/data-sharing/details');
  }
};