var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("Tooltip", {
    attrs: {
      content: _vm.content,
      placement: _vm.placement,
      transfer: _vm.transfer,
      maxWidth: _vm.maxWidth,
      theme: _vm.theme,
      delay: _vm.delay
    }
  }, [_vm.$slots.content ? _c("template", {
    slot: "content"
  }, [_vm._t("content")], 2) : _vm._e(), _c("Icon", {
    attrs: {
      type: _vm.icon,
      size: _vm.size,
      color: _vm.color
    }
  })], 2);
};

var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };