import "core-js/modules/es6.regexp.match";
import Vue from 'vue';
import VueRouter from 'vue-router';
import iView, { Message } from 'view-design';
import util from '@/libs/util';
import Setting from '@/setting';
import store from '@/store/index'; // 路由数据

import routes from "./routes";
Vue.use(VueRouter); // 导出路由 在 main.js 里使用

var router = new VueRouter({
  routes: routes,
  mode: Setting.routerMode
});
/**
 * 路由拦截
 * 权限验证
 */

router.beforeEach(function (to, from, next) {
  if (Setting.showProgressBar) iView.LoadingBar.start(); // 对nicole用户处理

  if (to.matched.some(function (_) {
    return _.meta.auth;
  })) {
    if (!util.cookies.get('token')) {
      next({
        name: 'login' // query: {
        //     redirect: to.fullPath
        // }

      });
    } else {
      next();
    }
  } else {
    next();
  }
});
router.afterEach(function (to) {
  if (Setting.showProgressBar) iView.LoadingBar.finish(); // console.log(to.name)
  // 多页控制 打开新的页面

  store.dispatch('admin/page/open', to); // 更改标题

  util.title({
    title: to.meta.title
  }); // 返回页面顶端

  window.scrollTo(0, 0);
});
router.onError(function (error) {
  var pattern = /Loading chunk (\d)+ failed/g;
  var isChunkLoadFailed = error.message.match(pattern);
  var targetPath = router.history.pending.fullPath;

  if (isChunkLoadFailed) {
    location.reload();
  }
});
export default router;