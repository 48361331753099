import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import _defineProperty from "/app/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty.js";
import "core-js/modules/es6.number.constructor";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import echarts from "echarts";
var defaultYAxis = {
  //坐标轴轴线相关设置。
  axisLabel: {
    color: "#8b93a6"
  },
  //坐标轴在 grid 区域中的分隔线。
  splitLine: {
    lineStyle: {
      type: "dotted",
      color: "#e5e9f2"
    }
  },
  axisLine: {
    lineStyle: {
      color: "#fff"
    }
  }
};
export default {
  name: "fk-echart",
  props: {
    // echart高度
    height: {
      type: [Number, String],
      default: 400
    },
    // 是否立即初始化一次视图
    immediate: {
      type: Boolean,
      default: false
    },
    notMerge: {
      type: Boolean,
      default: true
    },
    lazyUpdate: {
      type: Boolean,
      default: false
    },
    tooltip: {
      type: [Object, Array],
      default: function _default() {
        return {
          trigger: "axis"
        };
      }
    },
    grid: {
      type: Object,
      default: function _default() {
        return {
          top: "60",
          bottom: "60",
          left: "40",
          right: 60
        };
      }
    },
    legend: {
      type: Object,
      default: function _default() {
        return {
          type: "scroll"
        };
      }
    },
    legendData: {
      type: Array,
      default: function _default() {
        return null;
      }
    },
    legendOptions: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    showAxis: {
      type: Boolean,
      default: true
    },
    xAxis: {
      type: [Object, Array],
      default: function _default() {
        return {
          type: "category"
        };
      }
    },
    xAxisData: {
      type: Array,
      default: function _default() {
        return null;
      }
    },
    yAxis: {
      type: [Object, Array],
      default: function _default() {
        return {
          type: "value"
        };
      }
    },
    yAxisData: {
      type: Array,
      default: function _default() {
        return null;
      }
    },
    series: {
      type: Array,
      default: function _default() {
        return null;
      }
    },
    // 增加系统默认颜色
    color: {
      type: Array,
      default: function _default() {
        return ["#81d3f8", "#ec808d", "#70b603"];
      }
    },
    // 其他自定义 custom:未提供prop的，使用custom对象传进来，例如:custom={ title:'标题' }
    dataset: Object,
    title: Object,
    custom: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  mounted: function mounted() {
    this.init();
  },
  computed: {
    options: function options() {
      var tooltip = this.tooltip,
          grid = this.grid,
          legend = this.legend,
          legendData = this.legendData,
          legendOptions = this.legendOptions,
          xAxis = this.xAxis,
          xAxisData = this.xAxisData,
          yAxis = this.yAxis,
          yAxisData = this.yAxisData,
          series = this.series,
          custom = this.custom,
          color = this.color,
          title = this.title;

      var options = _objectSpread({
        title: title,
        tooltip: tooltip,
        grid: grid,
        color: color,
        xAxis: this.showAxis ? xAxis : null,
        yAxis: this.showAxis ? this.getYAxis() : null,
        legend: legend,
        series: series.map(function (item) {
          item.smooth = true;
          return item;
        })
      }, this.custom); // legend
      // legend = Object.assign({}, legend, legendOptions);
      // if (legendData) legend.data = legendData;
      // xAxis


      if (!Array.isArray(xAxis) && xAxisData) xAxis.data = xAxisData; // yAxis

      if (!Array.isArray(yAxis) && yAxisData) yAxis.data = yAxisData; //无数据

      if (options.series.length === 0 || options.series.every(function (serie) {
        return serie.data.length === 0;
      })) {
        options.title = {
          show: true,
          extStyle: {
            color: "grey",
            fontSize: 20
          },
          text: "暂无数据",
          left: "center",
          top: "center"
        };
      }

      return options;
    }
  },
  methods: {
    init: function init() {
      this.echart = echarts.init(this.$refs["chart"]);
      window.addEventListener("resize", this.echart.resize);
      if (this.immediate) this.update();
    },
    update: function update() {
      var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this.options;
      this.echart.setOption(options, this.notMerge);
    },
    getYAxis: function getYAxis() {
      var yAxis = this.yAxis;
      return yAxis ? Array.isArray(yAxis) ? yAxis.map(function (i) {
        return _objectSpread(_objectSpread({}, defaultYAxis), i);
      }) : _objectSpread(_objectSpread({}, yAxis), defaultYAxis) : _objectSpread({}, defaultYAxis);
    }
  },
  watch: {
    options: function options(newOptions) {
      this.update(newOptions);
    }
  },
  // beforeDestroied() {
  //   this.echart = null;
  // },
  beforeDestroied: function beforeDestroied() {
    window.removeEventListener("resize", this.echart.resize);
    this.echart.dispose();
  }
};