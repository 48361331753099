export default {
  path: '/sso/organization/staff-manage',
  name: 'staffManage',
  meta: {
    cache: true,
    auth: true,
    title: '员工管理'
  },
  component: function component() {
    return import('@/pages/sso/organization/staff-manage');
  }
};