var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("Poptip", {
    attrs: {
      trigger: "hover",
      title: _vm.title,
      width: 300,
      transfer: ""
    }
  }, [_c("template", {
    slot: "title"
  }, [_vm._t("title")], 2), _c("Button", {
    attrs: {
      size: _vm.size
    }
  }, [_vm._v("修改")]), _c("Row", {
    attrs: {
      slot: "content"
    },
    slot: "content"
  }, [_c("Col", {
    attrs: {
      span: 18
    }
  }, [_c("Input", {
    attrs: {
      placeholder: _vm.placeholder,
      size: _vm.size
    },
    model: {
      value: _vm.value,
      callback: function callback($$v) {
        _vm.value = typeof $$v === "string" ? $$v.trim() : $$v;
      },
      expression: "value"
    }
  })], 1), _c("Col", {
    attrs: {
      span: 6
    }
  }, [_c("Button", {
    attrs: {
      type: "primary",
      size: _vm.size
    },
    on: {
      click: function click($event) {
        return _vm.$emit("on-ok", _vm.value);
      }
    }
  }, [_vm._v("确定")])], 1)], 1)], 2);
};

var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };