export default {
  path: '/sso/organization/framework',
  name: 'organizationFramework',
  meta: {
    cache: true,
    auth: true,
    title: '组织架构'
  },
  component: function component() {
    return import('@/pages/sso/organization/framework');
  }
};