import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.array.find";
import "core-js/modules/es6.function.name";
import _defineProperty from "/app/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { mapState, mapGetters, mapActions } from 'vuex';
import menuSider from '@/menu/sider';
import Setting from '@/setting';
import { getAllSiderMenu } from '@/libs/system';
import { debounce } from 'lodash';
export default {
  name: 'iTabs',
  computed: _objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread({}, mapState('admin/page', ['opened', 'current', 'hideOpened'])), mapState('admin/layout', ['showTabsIcon', 'tabsFix', 'headerFix', 'headerStick', 'isMobile', 'menuCollapse'])), mapState('admin/menu', ['headerName'])), mapGetters('admin/menu', ['hideSider'])), {}, {
    classes: function classes() {
      return {
        'i-layout-tabs-fix': this.tabsFix
      };
    },
    isHeaderStick: function isHeaderStick() {
      var state = this.headerStick;
      if (this.hideSider) state = true;
      return state;
    },
    styles: function styles() {
      var style = {};

      if (this.tabsFix && !this.headerFix) {
        style.top = "".concat(64 - this.scrollTop, "px");
      }

      var menuWidth = this.isHeaderStick ? 0 : this.menuCollapse ? 80 : Setting.menuSideWidth;

      if (!this.isMobile && this.tabsFix) {
        style.width = "calc(100% - ".concat(menuWidth, "px)");
      }

      return style;
    }
  }),
  data: function data() {
    return {
      // 得到所有侧边菜单，并转为平级，查询图标用
      allSiderMenu: getAllSiderMenu(menuSider),
      scrollTop: 0,
      aaaaaa: true,
      hidepageIndex: -1
    };
  },
  methods: _objectSpread(_objectSpread({}, mapActions('admin/page', ['close', 'closeLeft', 'closeRight', 'closeOther', 'closeAll', 'dragDrop', 'resize', 'closeHideOpened'])), {}, {
    tabLabel: function tabLabel(h, page) {
      var title = h('span', page.meta.title || '未命名');
      var slot = []; // if (this.showTabsIcon) {
      //     const currentMenu = this.allSiderMenu.find(menu => menu.path === page.fullPath);
      //     let icon;
      //     if (currentMenu.icon) {
      //         icon = h('Icon', {
      //             props: {
      //                 type: currentMenu.icon
      //             }
      //         });
      //     } else if (currentMenu.custom) {
      //         icon = h('Icon', {
      //             props: {
      //                 custom: currentMenu.custom
      //             }
      //         });
      //     } else if (currentMenu.img) {
      //         icon = h('img', {
      //             attrs: {
      //                 src: currentMenu.img
      //             }
      //         });
      //     }
      //     if (icon) slot.push(icon);
      //     slot.push(title);
      // } else {
      //     slot.push(title);
      // }

      slot.push(title);
      var isFirst = false;

      if (page.name === 'home') {
        isFirst = true;
      }

      return h('div', {
        class: isFirst ? 'i-layout-tabs-title sb' : 'i-layout-tabs-title'
      }, slot);
    },
    handleClickTab: function handleClickTab(tabName) {
      var page = this.opened.find(function (page) {
        return page.fullPath === tabName;
      });
      var name = page.name,
          params = page.params,
          query = page.query;

      if (params) {
        params.noCache = true;
      }

      if (page) this.$router.push({
        name: name,
        params: params,
        query: query
      });
    },
    handleClickClose: function handleClickClose(tagName) {
      this.close({
        tagName: tagName
      });
    },
    // handleScroll () {
    //     if (this.tabsFix && !this.headerFix) {
    //         const scrollTop = document.body.scrollTop + document.documentElement.scrollTop;
    //         this.scrollTop = scrollTop > 64 ? 64 : scrollTop;
    //     }
    // },
    handleClickHide: function handleClickHide(tagName) {
      var page = this.hideOpened.find(function (page) {
        return page.fullPath === tagName;
      });
      var name = page.name,
          params = page.params,
          query = page.query;

      if (params) {
        params.noCache = true;
      }

      if (page) this.$router.push({
        name: name,
        params: params,
        query: query
      }); // 
      // const params = {
      //     pageSelect: this.current
      // };
      // this.$router.push({name})
      // this.$router.push(name)
    },
    handleDragDrop: function handleDragDrop(name, newName, a, b, names) {
      var _this = this;

      //  发现vuex中的数据改变了，但dom不更新 用vif强制刷新，，，，
      this.aaaaaa = false;
      var dragDropObj = {
        name: name,
        newName: newName,
        a: a,
        b: b,
        names: names
      };
      this.dragDrop(dragDropObj);
      setTimeout(function () {
        _this.aaaaaa = true;
      }, 20);
    },
    handleResize: function handleResize(nums) {
      var cha = document.querySelector('.ivu-tabs-nav').offsetWidth - document.querySelector('.ivu-tabs-nav-scroll').offsetWidth;
      var a = 0;

      if (cha > -140) {
        a = 1;
      }

      a += Math.floor(cha / 140); // 如果是第一次加载 默认加1

      if (nums === 1) {
        a += 1;
      }

      this.resize(a);
    },
    closeOne: function closeOne(i) {
      this.closeHideOpened(i);
    },
    handleClose: function handleClose(name) {
      var _this2 = this;

      var params = {
        pageSelect: this.current
      };

      switch (name) {
        case 'left':
          this.closeLeft(params);
          break;

        case 'right':
          this.closeRight(params);
          break;

        case 'other':
          this.closeOther(params);
          break;

        case 'all':
          this.closeAll();
          break;
      }

      this.$nextTick(function () {
        _this2.handleResize();
      });
    }
  }),
  mounted: function mounted() {
    var _this3 = this;

    // document.addEventListener('scroll', this.handleScroll, { passive: true });
    // this.handleScroll();
    window.addEventListener('resize', debounce(this.handleResize, 10), {
      passive: true
    });
    setTimeout(function () {
      _this3.handleResize(1);
    }, 0);
  },
  beforeDestroy: function beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  }
};